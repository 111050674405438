<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div>
      <b-row class="match-height">
        <b-col
          md="2"
          cols="12"
          class="mb-1"
        >
          <div>
            <div class="card-cradit white">
              <div class="p-1">
                <span>เติมเงินครั้งแรก ( เฉพาะเซียน )</span>
                <h3>
                  {{ today.depfirst_byzean.length }} <small>คน</small>
                </h3>
              </div>
              <div class="card-b d-flex justify-content-between">
                <small>รวม {{ today.depfirst_byzean ? Commas(today.depfirst_byzean.reduce((b, a) => b+ Number(a.depfirst_amount), 0).toFixed(2)) : 0 }}</small>
                <small>บาท</small>
              </div>
            </div>
          </div>
        </b-col>
        <b-col
          md="2"
          cols="12"
          class="mb-1"
        >
          <div>
            <div class="card-cradit-pink">
              <div class="p-1">
                <span>ฝากเงิน</span>
                <h3 class="text-white">
                  {{ today.deposit ? Commas(today.deposit.reduce((total, item) => total + item.amount, 0)) : 0 }}
                </h3>
              </div>
              <div class="card-b d-flex justify-content-between">
                <small>{{ today.deposit ? today.deposit.length : 0 }} รายการ</small>
                <!-- <small>{{ - }} สมาชิก</small> -->
              </div>
            </div>

          </div>
        </b-col>
        <b-col
          md="2"
          cols="12"
          class="mb-1"
        >
          <div>
            <div class="card-cradit-orange">
              <div class="p-1">
                <span>ถอนเงิน</span>
                <h3 class="text-white">
                  {{ today.withdraw ? Commas(today.withdraw.reduce((total, item) => total + item.amount, 0) ): 0 }}
                </h3>
              </div>
              <div class="card-b d-flex justify-content-between">
                <small>{{ today.withdraw ? today.withdraw.length : 0 }} รายการ</small>
                <!-- <small>0 สมาชิก</small> -->
              </div>
            </div>

          </div>
        </b-col>
        <b-col
          md="2"
          cols="12"
          class="mb-1"
        >
          <div>
            <div class="card-cradit-yellow">
              <div class="p-1">
                <span>โบนัส</span>
                <h3 class="text-white">
                  {{ today.deposit ? Commas(today.deposit.reduce((total, item) => total + item.bonus, 0)) : 0 }}
                </h3>
              </div>
              <div class="card-b d-flex justify-content-between">
                <small>-</small>
                <small>-</small>
              </div>
            </div>

          </div>
        </b-col>
        <b-col
          md="2"
          cols="12"
          class="mb-1"
        >
          <div>
            <div class="card-cradit-blue">
              <div class="p-1">
                <span>กำไร</span>
                <h3 class="text-white">
                  {{ today.deposit ? Commas(today.deposit.reduce((total, item) => total + item.amount, 0) - today.withdraw.reduce((total, item) => total + item.amount, 0)) : 0 }}
                </h3>
              </div>
              <div class="card-b d-flex justify-content-between">
                <small>-</small>
                <small>-</small>
              </div>
            </div>
          </div>
        </b-col>
        <b-col
          md="2"
          cols="12"
          class="mb-1"
        >
          <div>
            <div class="card-cradit white">
              <div class="p-1">
                <span>Truemoney</span>
                <h3 class="text-white">
                  {{ Commas(sunTrue) }}
                </h3>
              </div>
              <div class="card-b d-flex justify-content-between">
                <small>-</small>
                <small>จำนวน {{ today.truemoney ? Commas(today.truemoney.length) : 0 }} รายการ</small>
              </div>
            </div>

          </div>
        </b-col>
        <b-col
          md="2"
          cols="12"
          class="mb-1"
        >
          <div>
            <div class="card-cradit white">
              <div class="p-1">
                <span>สมัครและเติมวันนี้</span>
                <h3 class="text-white">
                  {{ today.regisanddep }}
                </h3>
              </div>
              <div class="card-b d-flex justify-content-between">
                <small> -</small>
                <small> -</small>
              </div>
            </div>

          </div>
        </b-col>
        <b-col
          md="2"
          cols="12"
          class="mb-1"
        >
          <div>
            <div class="card-cradit-pink">
              <div class="p-1">
                <span>Active วันนี้</span>
                <h3 class="text-white">
                  {{ Commas(today.active) }}
                </h3>
              </div>
              <div class="card-b d-flex justify-content-between">
                <small>-</small>
                <small>-</small>
              </div>
            </div>

          </div>
        </b-col>
        <b-col
          md="2"
          cols="12"
          class="mb-1"
        >
          <div>
            <div class="card-cradit-orange">
              <div class="p-1">
                <span>เติมเงินวันนี้</span>
                <h3 class="text-white">
                  {{ depsum }} <small>คน</small>
                </h3>
              </div>
              <div class="card-b d-flex justify-content-between">
                <small>-</small>
                <small>-</small>
              </div>
            </div>

          </div>
        </b-col>
        <b-col
          md="2"
          cols="12"
          class="mb-1"
        >
          <div>
            <div class="card-cradit-yellow">
              <div class="p-1">
                <span>เติมเงินครั้งแรก ( ทั้งหมด )</span>
                <h3 class="text-white">
                  {{ today.depfirst.length }} <small>คน</small>
                </h3>
              </div>
              <div class="card-b d-flex justify-content-between">
                <small>รวม {{ today.depfirst ? Commas(today.depfirst.reduce((b, a) => b+ Number(a.depfirst_amount), 0).toFixed(2)) : 0 }}</small>
                <small>บาท</small>
              </div>
            </div>

          </div>
        </b-col>
        <b-col
          md="2"
          cols="12"
          class="mb-1"
        >
          <div>
            <div class="card-cradit-blue">
              <div class="p-1">
                <span>SCB SMS</span>
                <h3 class="text-white">
                  <small>รวม</small> &nbsp; {{ Commas(scbsms.total) }}
                </h3>
              </div>
              <div class="card-b d-flex justify-content-between">
                <small>เติมแล้ว {{ Commas(scbsms.success) }}บาท</small>
                <small>เติมไม่ได้ {{ Commas(scbsms.waiting) }}บาท</small>
              </div>
            </div>

          </div>
        </b-col>
        <b-col
          md="2"
          cols="12"
          class="mb-1"
        >
          <div>
            <div class="card-cradit white">
              <div class="p-1">
                <span>KBANK SMS</span>
                <h3 class="text-white">
                  <small>รวม</small> &nbsp; {{ Commas(kbank.total) }}
                </h3>
              </div>
              <div class="card-b d-flex justify-content-between">
                <small>เติมแล้ว {{ Commas(kbank.success) }}บาท</small>
                <small>เติมไม่ได้ {{ Commas(kbank.waiting) }}บาท</small>
              </div>
            </div>

          </div>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col md="3">
          <div class="text-center">
            <span
              class="h4 pl-1 pr-1"
              style="background-color: #f8f8f8;"
            >
              TOP 5 สมาชิกฝากเงิน
            </span>

            <hr class="border-3 mt-n75">
          </div>

          <div
            v-for="item in Ranking.Dep"
            :key="item"
            class="card-bank5 font-weight-bolder"
          >
            <div
              class="d-flex justify-content-between align-items-center"
              @click="$router.push({ name: 'Deposit-Withdraw', params: { id: item.username }})"
            >
              <div class="d-flex align-items-center">
                <img
                  src="/rank/top1.png"
                  alt=""
                  height="50"
                  class="mr-1"
                >
                <div>
                  <small class="font-weight-bolder mb-0 text-white">{{ item.name }}</small>
                  <h5 class="font-weight-bolder mb-0 text-white">
                    {{ item.username }}
                  </h5>
                  <small
                    class="text-white"
                  >{{ item.date }}
                  </small>
                </div>
              </div>

              <h5 class="font-weight-bolder mb-0 text-white">
                {{ item.amount ? Commas(item.amount) : 0 }} บาท
              </h5>

            </div>

          </div>
        </b-col>

        <b-col md="3">
          <div class="text-center">
            <span
              class="h4 pl-1 pr-1"
              style="background-color: #f8f8f8;"
            >
              TOP 5 สมาชิกถอนเงิน
            </span>

            <hr class="border-3 mt-n75">
          </div>

          <div
            v-for="item in Ranking.With"
            :key="item"
            class="card-bank4 font-weight-bolder"
          >
            <div
              class="d-flex justify-content-between align-items-center"
              @click="$router.push({ name: 'Deposit-Withdraw', params: { id: item.username }})"
            >
              <div class="d-flex align-items-center">
                <img
                  src="/rank/top1.png"
                  alt=""
                  height="50"
                  class="mr-1"
                >
                <div>
                  <small class="font-weight-bolder mb-0 text-white">{{ item.name }}</small>
                  <h5 class="font-weight-bolder mb-0 text-white">
                    {{ item.username }}
                  </h5>
                  <small
                    class="text-white"
                  >{{ item.date }}
                  </small>
                </div>
              </div>

              <h5 class="font-weight-bolder mb-0 text-white">
                {{ item.amount ? Commas(item.amount) : 0 }} บาท
              </h5>

            </div>

          </div>
        </b-col>

        <b-col md="3">
          <div class="text-center">
            <span
              class="h4 pl-1 pr-1"
              style="background-color: #f8f8f8;"
            >
              รายการฝากล่าสุด
            </span>

            <hr class="border-3 mt-n75">
          </div>

          <div
            v-for="item in DepositLast"
            :key="item"
            class="card-bank2 font-weight-bolder"
          >

            <div class="d-flex justify-content-between align-items-center">
              <div>
                <small
                  style="color: blue"
                >{{ item.username }}
                  <small class="text-secondary">-สถานะ-</small>
                  <small
                    v-if="item.status === 'success' || item.status === 'manual_success'"
                    class="text-success"
                  >{{ item.status }}</small>
                  <small
                    v-if="item.status === 'error'"
                    class="text-danger"
                  >{{ item.status }}</small>
                  <small
                    v-if="item.status === 'waithing' || item.status === 'processing'"
                    class="text-warning"
                  >{{ item.status }}</small>
                </small>
                <h4 class="font-weight-bolder mb-0">
                  {{ item.amount.toFixed(2) }}
                </h4>
                <small
                  class="text-info"
                >
                  {{ TimeAdd(item.created_at) }}
                </small>
              </div>

              <div class="align-items-center d-flex">
                <div
                  class="text-center bank-img"
                  :style="`background-color: ${item.bg};`"
                >
                  <img
                    height="20"
                    :src="`/bankIcon/${item.path_photo}`"
                    alt=""
                  >
                </div>
                <i
                  class="far fa-angle-right"
                  style="margin: 10px"
                />
                <div
                  class="text-center bank-img"
                  style="background-color: #4c2786"
                >
                  <img
                    height="20"
                    src="/bankIcon/scb.png"
                    alt=""
                  >
                </div>
              </div>
            </div>

          </div>
        </b-col>

        <b-col md="3">
          <div class="text-center">
            <span
              class="h4 pl-1 pr-1"
              style="background-color: #f8f8f8;"
            >
              รายการถอนล่าสุด
            </span>

            <hr class="border-3 mt-n75">
          </div>

          <div
            v-for="item in WithdrawLast"
            :key="item"
            class="card-bank3 font-weight-bolder"
          >

            <div class="d-flex justify-content-between align-items-center">
              <div>
                <small
                  style="color: blue"
                >{{ item.username }}
                  <small class="text-secondary">-สถานะ-</small>
                  <small
                    v-if="item.status === 'success' || item.status === 'manual_success'"
                    class="text-success"
                  >{{ item.status }}</small>
                  <small
                    v-if="item.status === 'error'"
                    class="text-danger"
                  >{{ item.status }}</small>
                  <small
                    v-if="item.status === 'waithing' || item.status === 'processing'"
                    class="text-warning"
                  >{{ item.status }}</small>
                </small>

                <h4 class="font-weight-bolder mb-0">
                  {{ item.amount.toFixed(2) }}
                </h4>

                <small
                  class="text-info"
                >{{ TimeAdd(item.created_at) }}</small>
              </div>
              <div class="align-items-center d-flex">
                <div
                  class="text-center bank-img"
                  :style="`background-color: ${item.frome === 'kbank' ? '#009345' : '#4c2786'}`"
                >
                  <img
                    v-if="item.frome === 'kbank'"
                    height="20"
                    src="/bankIcon/kbank.svg"
                    alt=""
                  >
                  <img
                    v-else-if="item.frome === 'scb'"
                    height="20"
                    src="/bankIcon/scb.png"
                    alt=""
                  >
                  <img
                    v-else
                    height="20"
                    src="/bankIcon/scb.png"
                    alt=""
                  >
                </div>
                <i
                  class="far fa-angle-right"
                  style="margin: 10px"
                />
                <div
                  class="text-center bank-img"
                  :style="`background-color: ${item.bg};`"
                >
                  <img
                    height="20"
                    :src="`/bankIcon/${item.path_photo}`"
                    alt=""
                  >
                </div>
              </div>
            </div>

          </div>
        </b-col>

        <b-col md="12">
          <b-card
            v-if="!showTable"
            no-body
            class="mt-2"
          >
            <div>

              <div class="m-1 d-flex align-items-center">
                <b-form-select
                  v-model="selectedmonth"
                  :options="month"
                  value-field="value"
                  text-field="text"
                  class="mr-50 w-25"
                />

                <b-form-select
                  v-model="selectedyear"
                  :options="year"
                  value-field="value"
                  text-field="text"
                  class="mr-50 w-25"
                />

                <b-button
                  variant="gradient-primary"
                  class="mr-50"
                  @click="getMonthly"
                >
                  <i class="fal fa-search-dollar" />
                  ค้นหา
                </b-button>

                <b-button
                  variant="gradient-success"
                  @click="ExportExcel()"
                >
                  Export
                </b-button>
              </div>
              <div class="wrap-table100">
                <div class="table100 ver1">
                  <div class="table100-firstcol">
                    <table>
                      <thead>
                        <tr class="row100 head">
                          <th class="cell100 column1">
                            รายละเอียด/วันที่
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="row100 body">
                          <td class="cell100 column1">
                            สมัครวันนี้
                          </td>
                        </tr>
                        <tr class="row100 body">
                          <td class="cell100 column1">
                            สมัครและเติมวันนี้
                          </td>
                        </tr>

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            เติมเงินครั้งแรก (จำนวนคน)
                          </td>
                        </tr>

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            เติมเงินครั้งแรก (ยอดเงิน)
                          </td>
                        </tr>

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            เติมเงินครั้งแรกเฉพาะเซียน (จำนวนคน)
                          </td>
                        </tr>

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            เติมเงินครั้งแรกเฉพาะเซียน (ยอดเงิน)
                          </td>
                        </tr>

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            จำนวนฝากวันนี้
                          </td>
                        </tr>

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            Active วันนี้
                          </td>
                        </tr>

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            ยอดฝาก
                          </td>
                        </tr>

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            ยอดถอน
                          </td>
                        </tr>
                        <tr class="row100 body">
                          <td class="cell100 column1">
                            ยอดรวม
                          </td>
                        </tr>

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            โบนัส
                          </td>
                        </tr>

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            Facebook
                          </td>
                        </tr>

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            Google
                          </td>
                        </tr>

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            Youtube
                          </td>
                        </tr>
                        <tr class="row100 body">
                          <td class="cell100 column1">
                            TikTok
                          </td>
                        </tr>
                        <tr class="row100 body">
                          <td class="cell100 column1">
                            SMS
                          </td>
                        </tr>

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            เพื่อนแนะนำ
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="wrap-table100-nextcols js-pscroll">
                    <div class="table100-nextcols">
                      <table>
                        <thead>
                          <tr class="row100 head">
                            <th
                              v-for="day in endDateTable"
                              :key="day"
                              class="cell100 column2"
                            >
                              {{ day }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="row100 body">
                            <td
                              v-for="item in monthly.regis"
                              :key="item"
                              class="cell100 column2"
                            >
                              {{ Commas(item) }}
                            </td>
                          </tr>
                          <tr class="row100 body">
                            <td
                              v-for="item in monthly.regisdep"
                              :key="item"
                              class="cell100 column2"
                            >
                              {{ Commas(item) }}
                            </td>
                          </tr>
                          <tr class="row100 body">
                            <td
                              v-for="item in monthly.depfirst_count"
                              :key="item"
                              class="cell100 column2"
                            >
                              {{ Commas(item) }}
                            </td>
                          </tr>
                          <tr class="row100 body">
                            <td
                              v-for="item in monthly.depfirst_sum"
                              :key="item"
                              class="cell100 column2"
                            >
                              {{ Commas(item) }}
                            </td>
                          </tr>
                          <tr class="row100 body">
                            <td
                              v-for="item in monthly.depfirst_byzean_count"
                              :key="item"
                              class="cell100 column2"
                            >
                              {{ Commas(item) }}
                            </td>
                          </tr>
                          <tr class="row100 body">
                            <td
                              v-for="item in monthly.depfirst_byzean_sum"
                              :key="item"
                              class="cell100 column2"
                            >
                              {{ Commas(item) }}
                            </td>
                          </tr>
                          <tr class="row100 body">
                            <td
                              v-for="item in monthly.nodeposit"
                              :key="item"
                              class="cell100 column2"
                            >
                              {{ Commas(item) }}
                            </td>
                          </tr>

                          <tr class="row100 body">
                            <td
                              v-for="item in monthly.active"
                              :key="item"
                              class="cell100 column2"
                            >
                              {{ Commas(item) }}
                            </td>
                          </tr>

                          <tr class="row100 body">
                            <td
                              v-for="item in monthly.deposit"
                              :key="item"
                              class="cell100 column2"
                            >
                              {{ Commas(item) }}
                            </td>
                          </tr>
                          <tr class="row100 body">
                            <td
                              v-for="item in monthly.withdraw"
                              :key="item"
                              class="cell100 column2"
                            >
                              {{ Commas(item) }}
                            </td>
                          </tr>
                          <tr class="row100 body">
                            <td
                              v-for="item in monthly.total"
                              :key="item"
                              class="cell100 column2"
                            >
                              {{ Commas(item) }}
                            </td>
                          </tr>
                          <tr class="row100 body">
                            <td
                              v-for="item in monthly.bonus"
                              :key="item"
                              class="cell100 column2"
                            >
                              {{ Commas(item) }}
                            </td>
                          </tr>
                          <tr class="row100 body">
                            <td
                              v-for="item in monthly.facebook"
                              :key="item"
                              class="cell100 column2"
                            >
                              {{ Commas(item) }}
                            </td>
                          </tr>
                          <tr class="row100 body">
                            <td
                              v-for="item in monthly.google"
                              :key="item"
                              class="cell100 column2"
                            >
                              {{ Commas(item) }}
                            </td>
                          </tr>
                          <tr class="row100 body">
                            <td
                              v-for="item in monthly.youtube"
                              :key="item"
                              class="cell100 column2"
                            >
                              {{ Commas(item) }}
                            </td>
                          </tr>
                          <tr class="row100 body">
                            <td
                              v-for="item in monthly.tiktok"
                              :key="item"
                              class="cell100 column2"
                            >
                              {{ Commas(item) }}
                            </td>
                          </tr>
                          <tr class="row100 body">
                            <td
                              v-for="item in monthly.sms"
                              :key="item"
                              class="cell100 column2"
                            >
                              {{ Commas(item) }}
                            </td>
                          </tr>
                          <tr class="row100 body">
                            <td
                              v-for="item in monthly.friend"
                              :key="item"
                              class="cell100 column2"
                            >
                              {{ Commas(item) }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div class="table100-lastcol">
                    <table>
                      <thead>
                        <tr class="row100 head">
                          <th class="cell100 column1">
                            รวม
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="row100 body">
                          <td class="cell100 column1">
                            {{
                              monthly.regis ?
                                Commas(
                                  monthly.regis.reduce(
                                    (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                    0,
                                  ),
                                ) : 0
                            }}
                            คน
                          </td>
                        </tr>

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            {{ monthly.regisdep ?
                              Commas(
                                monthly.regisdep.reduce(
                                  (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                  0,
                                ),
                              ) : 0
                            }}
                            คน
                          </td>
                        </tr>

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            {{ monthly.depfirst_count ?
                              Commas(
                                monthly.depfirst_count.reduce(
                                  (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                  0,
                                ),
                              ) : 0
                            }}
                            คน
                          </td>
                        </tr>

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            {{ monthly.depfirst_sum ?
                              Commas(
                                monthly.depfirst_sum.reduce(
                                  (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                  0,
                                ),
                              ) : 0
                            }}
                            บาท
                          </td>
                        </tr>

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            {{ monthly.depfirst_byzean_count ?
                              Commas(
                                monthly.depfirst_byzean_count.reduce(
                                  (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                  0,
                                ),
                              ) : 0
                            }}
                            คน
                          </td>
                        </tr>

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            {{ monthly.depfirst_byzean_sum ?
                              Commas(
                                monthly.depfirst_byzean_sum.reduce(
                                  (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                  0,
                                ),
                              ) : 0
                            }}
                            บาท
                          </td>
                        </tr>

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            {{ monthly.nodeposit ?
                              Commas(
                                monthly.nodeposit.reduce(
                                  (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                  0,
                                ),
                              ) : 0
                            }}
                            รายการ
                          </td>
                        </tr>

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            {{ monthly.active ?
                              Commas(
                                monthly.active.reduce(
                                  (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                  0,
                                ),
                              ) : 0
                            }}
                            คน
                          </td>
                        </tr>

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            {{ monthly.deposit ?
                              Commas(
                                monthly.deposit.reduce(
                                  (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                  0,
                                ),
                              ) : 0
                            }}
                            บาท
                          </td>
                        </tr>

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            {{ monthly.withdraw ?
                              Commas(
                                monthly.withdraw.reduce(
                                  (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                  0,
                                ),
                              ) : 0
                            }}
                            บาท
                          </td>
                        </tr>

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            {{ monthly.total ?
                              Commas(
                                monthly.total.reduce(
                                  (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                  0,
                                )
                              ) : 0
                            }}
                            บาท
                          </td>
                        </tr>

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            {{ monthly.bonus ?
                              Commas(
                                monthly.bonus.reduce(
                                  (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                  0,
                                ),
                              ) : 0
                            }}
                            บาท
                          </td>
                        </tr>

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            {{ monthly.facebook ?
                              Commas(
                                monthly.facebook.reduce(
                                  (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                  0,
                                ),
                              ) : 0
                            }}
                            คน
                          </td>
                        </tr>

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            {{ monthly.google ?
                              Commas(
                                monthly.google.reduce(
                                  (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                  0,
                                ),
                              ) : 0
                            }}
                            คน
                          </td>
                        </tr>

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            {{ monthly.youtube ?
                              Commas(
                                monthly.youtube.reduce(
                                  (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                  0,
                                ),
                              ) : 0
                            }}
                            คน
                          </td>
                        </tr>
                        <tr class="row100 body">
                          <td class="cell100 column1">
                            {{ monthly.tiktok ?
                              Commas(
                                monthly.tiktok.reduce(
                                  (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                  0,
                                ),
                              ) : 0
                            }}
                            คน
                          </td>
                        </tr>
                        <tr class="row100 body">
                          <td class="cell100 column1">
                            {{ monthly.sms ?
                              Commas(
                                monthly.sms.reduce(
                                  (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                  0,
                                ),
                              ) : 0
                            }}
                            คน
                          </td>
                        </tr>

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            {{ monthly.friend ?
                              Commas(
                                monthly.friend.reduce(
                                  (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                  0,
                                ),
                              ) : 0
                            }}
                            คน
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BOverlay,
  BIconController,
  BFormSelect,
  BButton,
} from 'bootstrap-vue'

import momenttz from 'moment-timezone'
import axios from 'axios'
import BUID from 'uniquebrowserid'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BOverlay,
    BIconController,
    BFormSelect,
    BButton,
  },
  data() {
    return {
      Ranking: {
        Dep: [],
        With: [],
      },
      showTable: true,
      DepositLast: [],
      WithdrawLast: [],
      selectedyear: momenttz()
        .subtract(1, 'days')
        .format('YYYY'),
      selectedmonth: momenttz()
        .subtract(1, 'days')
        .format('M'),
      DepositLasts: [

      ],
      month: [
        { value: null, text: 'เลือกเดือน' },
        { value: '1', text: 'มกราคม' },
        { value: '2', text: 'กุมภาพันธ์' },
        { value: '3', text: 'มีนาคม' },
        { value: '4', text: 'เมษายน' },
        { value: '5', text: 'พฤษภาคม' },
        { value: '6', text: 'มิถุนายน' },
        { value: '7', text: 'กรกฎาคม' },
        { value: '8', text: 'สิงหาคม' },
        { value: '9', text: 'กันยายน' },
        { value: '10', text: 'ตุลาคม' },
        { value: '11', text: 'พฤศจิกายน' },
        { value: '12', text: 'ธันวาคม' },
      ],
      year: [
        { value: null, text: 'เลือกปี' },
        { value: '2021', text: '2564' },
        { value: '2022', text: '2565' },
        { value: '2023', text: '2566' },
      ],
      show: true,
      today: [{
        deamount: 0,
        nodeposit: 0,
        memdeposit: 0,
        weamount: 0,
        debonus: 0,
        total: 0,
        regisnew: 0,
        activeNew: 0,
        memdepositfirstSum: 0,

      }],
      monthly: {
        regis: [],
        regisdep: [],
        nodeposit: [],
        active: [],
        deposit: [],
        withdraw: [],
        total: [],
        bonus: [],
        nobet: [],
        facebook: [],
        google: [],
        youtube: [],
        tiktok: [],
        sms: [],
        friend: [],
      },
      depositSeries: [
        {
          name: 'deposit',
          data: [],
        },
      ],
      withdrawSeries: [
        {
          name: 'withdraw',
          data: [],
        },
      ],
      bonusSeries: [
        {
          name: 'bonus',
          data: [],
        },
      ],
      chartData: {},
      customersPie: {
        series: [1200, 690, 258, 149],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: ['Totaltoday', 'Deposit', 'Withdraw', 'Bonus'],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '1.5rem',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1.5rem',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
          dataLabels: {
            enabled: true,
          },
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '14px',
            fontFamily: 'Prompt',
            color: '#fff',
          },
          stroke: {
            show: false,
          },
          colors: ['#ffc800', '#28c76f', '#FF005D', '#ff9f43'],
        },
      },
      data: {},
      sunTrue: 0,
      scbconnect: {
        total: 0,
        waiting: 0,
        success: 0,
      },
      scbsms: {
        total: 0,
        waiting: 0,
        success: 0,
      },
      kbank: {
        total: 0,
        waiting: 0,
        success: 0,
      },
      depsum: 0,
      ShowToday: false,
      ShowMonthly: false,
      ShowDeposit: false,
      ShowWithdraw: false,
      AgLog: {
        ip: null,
        latitude: '',
        longitude: '',
        buid: new BUID().completeID(),
      },
    }
  },
  created() {
    // this.interval = setInterval(() => {
    //   this.getToday()
    // }, 60000)
  },
  destroyed() {
    clearInterval(this.interval)
  },
  mounted() {
    this.getToday()
    this.getMonthly()
    this.GetDepositLast()
    this.GetWithdrawLast()
    this.GetRankDepWith()
    this.LogAdmin()
  },
  methods: {
    GetLocation() {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(position => {
          const { latitude } = position.coords
          const { longitude } = position.coords
          this.AgLog.latitude = latitude
          this.AgLog.longitude = longitude
        })
      }
    },
    async GetIp() {
      await axios
        .get('https://api.ipify.org')
        .then(response => {
          this.AgLog.ip = response.data
        })
        .catch(error => console.log(error))
    },
    async LogAdmin() {
      await this.GetLocation()
      await this.GetIp()
      const params = {
        on_page: 'dashboard',
        ip: this.AgLog.ip,
        latitude: this.AgLog.latitude,
        longitude: this.AgLog.longitude,
        buid: this.AgLog.buid,
      }
      this.$http.get('/admin/log', { params })
    },
    ExportExcel() {
      this.$http
        .get(`dashboard/export/${this.selectedyear}/${this.selectedmonth}`, { responseType: 'blob' })
        .then(response => {
          // console.log(response.data)
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(
            new Blob([response.data]),
          )
          link.setAttribute('download', 'Report Months.xlsx')
          document.body.appendChild(link)
          link.click()
        })
        .catch(error => console.log(error))
    },
    TimeAdd(data) {
      const x = momenttz(data).tz('Asia/Bangkok').add('hours', 7).format('DD/MM/YYYY HH:mm:ss')
      return x
    },
    GetRankDepWith() {
      this.$http
        .get('/history/DepWithRanking')
        .then(response => {
          this.Ranking = response.data
        })
        .catch(error => console.log(error))
    },
    GetDepositLast() {
      this.$http
        .get('/GetDepositLast')
        .then(response => {
          this.DepositLast = response.data
        })
        .catch(error => console.log(error))
    },
    GetWithdrawLast() {
      this.$http
        .get('/GetWithdrawLast')
        .then(response => {
          this.WithdrawLast = response.data
          // console.log(this.WithdrawLast)
        })
        .catch(error => console.log(error))
    },
    async getToday() {
      this.show = true
      await this.$http
        .get('/dashboard/index')
        .then(async response => {
          this.show = false
          this.today = response.data
          const arry = []
          // eslint-disable-next-line no-restricted-syntax
          for (const dep of response.data.deposit) {
            if (arry.indexOf(dep.username) === -1) {
              arry.push(dep.username)
              this.depsum += 1
            }
          }
          // eslint-disable-next-line no-restricted-syntax
          for (const s of response.data.truemoney) {
            this.sunTrue += Number(s.amount)
          }
          // eslint-disable-next-line no-restricted-syntax
          for (const s of response.data.scbconnect) {
            this.scbconnect.total += Number(s.amount)
            if (s.status === 'waiting') {
              this.scbconnect.waiting += Number(s.amount)
            } else {
              this.scbconnect.success += Number(s.amount)
            }
          }

          // eslint-disable-next-line no-restricted-syntax
          for (const s of response.data.scbsms) {
            // if (s.status !== 'off') {
            this.scbsms.total += Number(s.amount)
            if (s.status === 'waiting') {
              this.scbsms.waiting += Number(s.amount)
            } else {
              this.scbsms.success += Number(s.amount)
            }
            // }
          }
          // eslint-disable-next-line no-restricted-syntax
          for (const s of response.data.kbank) {
            this.kbank.total += Number(s.amount)
            if (s.status === 'waiting') {
              this.kbank.waiting += Number(s.amount)
            } else {
              this.kbank.success += Number(s.amount)
            }
          }
        })
        .catch(error => console.log(error))
    },
    async getMonthly() {
      if (this.selectedmonth) {
        this.showTable = true
        this.ChangeEndDate(this.selectedyear, this.selectedmonth)
        this.ClearMonthly()
        const params = {
          selectedmonth: this.selectedmonth,
          selectedyear: this.selectedyear,
        }
        await this.$http
          .get('/dashboard/getmonthly', {
            params,
          })
          .then(response => {
            this.showTable = false
            // console.log(response.data)
            // this.monthly = response.data
            this.CalMonthly(response.data)
            // console.log(this.monthly.regis)
          })
          .catch(error => console.log(error))
      }
    },
    ChangeEndDate(selectedyear, selectedmonth) {
      const FindEndDay = `${selectedyear}-${
        selectedmonth < 10 ? 0 + selectedmonth : selectedmonth
      }-01 00:00:00`
      this.endDate = Number(
        momenttz(FindEndDay)
          .endOf('month')
          .format('D'),
      )
      this.endDateTable = this.endDate + 1
    },
    CalMonthly(Report) {
      // eslint-disable-next-line no-plusplus
      for (let i = 1; i <= this.endDate; i++) {
        // console.log(i)
        const Index = Report.findIndex(({ day }) => day === i)
        if (Index >= 0) {
          this.monthly.regis.push(Report[Index].regis)
          this.monthly.regisdep.push(Report[Index].regisdep)

          this.monthly.depfirst_count.push(Report[Index].depfirst_count)
          this.monthly.depfirst_sum.push(Report[Index].depfirst_sum)
          this.monthly.depfirst_byzean_count.push(Report[Index].depfirst_byzean_count)
          this.monthly.depfirst_byzean_sum.push(Report[Index].depfirst_byzean_sum)

          this.monthly.nodeposit.push(Report[Index].nodeposit)
          this.monthly.active.push(Report[Index].active)
          this.monthly.deposit.push(Report[Index].deposit)
          this.monthly.withdraw.push(Report[Index].withdraw)
          this.monthly.total.push(Report[Index].total)
          this.monthly.bonus.push(Report[Index].bonus)
          this.monthly.facebook.push(Report[Index].facebook)
          this.monthly.google.push(Report[Index].google)
          this.monthly.youtube.push(Report[Index].youtube)
          this.monthly.friend.push(Report[Index].friend)
          this.monthly.tiktok.push(Report[Index].tiktok)
          this.monthly.sms.push(Report[Index].sms)
        } else {
          this.monthly.regis.push(0)
          this.monthly.regisdep.push(0)

          this.monthly.depfirst_count.push(0)
          this.monthly.depfirst_sum.push(0)
          this.monthly.depfirst_byzean_count.push(0)
          this.monthly.depfirst_byzean_sum.push(0)

          this.monthly.nodeposit.push(0)
          this.monthly.active.push(0)
          this.monthly.deposit.push(0)
          this.monthly.withdraw.push(0)
          this.monthly.total.push(0)
          this.monthly.bonus.push(0)
          this.monthly.facebook.push(0)
          this.monthly.google.push(0)
          this.monthly.youtube.push(0)
          this.monthly.friend.push(0)
          this.monthly.tiktok.push(0)
          this.monthly.sms.push(0)
        }
      }
    },
    ClearMonthly() {
      this.monthly = {
        regis: [],
        regisdep: [],
        depfirst_count: [],
        depfirst_sum: [],
        depfirst_byzean_count: [],
        depfirst_byzean_sum: [],
        nodeposit: [],
        active: [],
        deposit: [],
        withdraw: [],
        total: [],
        bonus: [],
        facebook: [],
        google: [],
        youtube: [],
        tiktok: [],
        sms: [],
        friend: [],
      }
    },
    Commas(x) {
      if (!x) {
        return 0
      } if (x % 1 !== 0) {
        return Number(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
  },
}
</script>

<style scoped>
.card-bank-img {
  position: absolute;
  top: 45%;
  right: 8%;
}
.bank-img {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 6px;
}
.card-bank1 {
  background: #FFF;
  width: 100%;
  height: 100%;
  padding: 5px 15px;
  border-radius: 5px;
  border-left: 4px solid rgb(31, 184, 0);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  margin-bottom: 15px;
}
.card-bank2 {
  background: #FFF;
  width: 100%;
  height: auto;
  padding: 5px 15px;
  border-radius: 5px;
  border-left: 4px solid rgb(0, 193, 6);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  margin-bottom: 15px;
}
.card-bank3 {
  background: #FFF;
  width: 100%;
  height: auto;
  padding: 5px 15px;
  border-radius: 5px;
  border-left: 4px solid red;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  margin-bottom: 15px;
}

.card-bank4 {
  background:linear-gradient(126.21deg, #FF39C9 0%, #462296 105.24%);
  width: 100%;
  height: auto;
  padding: 7px 15px;
  border-radius: 5px;
  border-left: 4px solid #462296;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  margin-bottom: 15px;
}

.card-bank5 {
  background: linear-gradient(45deg, #ff005d, #ff00a1);
  width: 100%;
  height: auto;
  padding: 7px 15px;
  border-radius: 5px;
  border-left: 4px solid #ffb1e2;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  margin-bottom: 15px;
}
/*//////////////////////////////////////////////////////////////////
[ Scroll bar ]*/
.js-pscroll {
  position: relative;
  overflow: hidden;
}

.table100 .ps__rail-x {
  z-index: 1010;
  height: 6px;
  background-color: transparent;
  opacity: 1 !important;
  bottom: 10px;
}

.table100 .ps__rail-x::before {
  content: '';
  display: block;
  position: absolute;
  background-color: #ff0000;
  height: 100%;
  width: calc(100% - 30px);
  left: 15px;
  top: 0px;
}

.table100 .ps__rail-x .ps__thumb-x {
  height: 100%;
  bottom: 0;
  background-color: transparent;
  opacity: 1 !important;
}

.table100 .ps__rail-x .ps__thumb-x::before {
  content: '';
  display: block;
  position: absolute;
  background-color: #191919;
  height: 100%;
  width: calc(100% - 30px);
  top: 0;
  left: 15px;
}

/*//////////////////////////////////////////////////////////////////
[ Table ]*/

.limiter {
  width: 100%;
  margin: 0 auto;
  background: #fa71cd;
  background: -webkit-linear-gradient(bottom, #c471f5, #fa71cd);
  background: -o-linear-gradient(bottom, #c471f5, #fa71cd);
  background: -moz-linear-gradient(bottom, #c471f5, #fa71cd);
  background: linear-gradient(bottom, #c471f5, #fa71cd);
}

.container-table100 {
  max-width: 1366px;
  margin: 0 auto;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 5px 100px;
}

.wrap-table100 {
  width: 100%;
}

/*//////////////////////////////////////////////////////////////////
[ Table ]*/
.table100 {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
}

table {
  width: 100%;
}

th,
td {
  font-weight: unset;
  padding-right: 10px;
  height: 30px;
}

.column1 {
  color: rgb(0, 0, 0);
  background-color: #ffffff;
  width: 180px;
  padding-left: 20px;
}

.column2 {
  width: 120px;
  height: 25px;
  /* padding-left: 5px; */
}

.column3 {
  height: 25px;
  width: 120px;
}

.column4 {
  height: 25px;
  width: 120px;
}

.column5 {
  height: 25px;
  width: 120px;
}

.column6 {
  height: 25px;
  width: 120px;
}

.column7 {
  height: 25px;
  width: 120px;
}

.column8 {
  height: 25px;
  width: 120px;
}

.table100 th {
  padding-top: 21px;
  padding-bottom: 21px;
}

.table100 td {
  padding-top: 16px;
  padding-bottom: 16px;
}

/*==================================================================
[ Fix col ]*/
.table100 {
  width: 100%;
  position: relative;
}

.table100-firstcol {
  background-color: #fff;
  position: absolute;
  z-index: 1;
  width: 245px;
  top: 0;
  left: 0;
}

.table100-lastcol {
  background-color: #fff;
  position: absolute;
  z-index: 1;
  width: 155px;
  top: 0;
  right: 0;
}

.table100-firstcol table {
  background-color: #fff;
}

.wrap-table100-nextcols {
  width: 100%;
  overflow: auto;
  padding-left: 245px;
  padding-bottom: 28px;
}

.table100-nextcols table {
  table-layout: fixed;
}

.shadow-table100-firstcol {
  box-shadow: 8px 0px 10px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 8px 0px 10px 0px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 8px 0px 10px 0px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 8px 0px 10px 0px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 8px 0px 10px 0px rgba(0, 0, 0, 0.05);
}

.table100-firstcol table {
  background-color: transparent;
}

/*==================================================================
[ Ver1 ]*/

.table100.ver1 th {
  font-size: 14px;
  color: #ffffff;
  background: #313452;
  line-height: 0.1;
  text-transform: uppercase;
}

.table100.ver1 td {
  font-size: 0.9rem;
  line-height: 0.1;
}

.table100.ver1 .table100-firstcol td {
  color: #000000;
}

.table100.ver1 .table100-nextcols td {
  color: #000000;
}

.table100.ver1 tr {
  border-bottom: 0.1px solid #6b6b6b4f;
}
</style>
